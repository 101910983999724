
import { defineComponent, computed } from 'vue';
import { useRoute } from 'vue-router';
import NavButton from '@/components/NavButton.vue';
import NavConnect from '@/components/NavConnect.vue';

export default defineComponent({
  components: { NavButton, NavConnect },
  setup() {
    const route = useRoute();
    const path = computed(() => route.path);

    const menu = [
      { name: 'STAKING HOME', url: '/' },
      // { name: 'FARM MANAGER', url: '/manager' },
      { name: 'FARM', url: '/farmer/2cXNuoCL321XHZnz3qNJfwdmgugY1NGVjwuHBAaxA3Mt' },
    ];

    return {
      menu,
      path,
    };
  },
});
