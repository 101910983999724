
import { computed, defineComponent, watch, ref, onMounted } from 'vue';
import { WalletName } from '@solana/wallet-adapter-wallets';
import useCluster, { Cluster } from '@/composables/cluster';
import useWallet from '@/composables/wallet';
import { notify } from '@kyvg/vue3-notification';

export default defineComponent({
  setup() {
    // cluster
    const { cluster, setCluster, getClusterURL } = useCluster();
    const { getWalletName, setWallet, wallet, getWalletAddress } = useWallet();
    const pubKey = ref<string>();
    const connoptions = ref<boolean>();

        onMounted(async () => {
      setCluster(Cluster.Mainnet);
    });


    const chosenCluster = computed({
      get() {
        return cluster.value;
      },
      set(newVal: Cluster) {
        setCluster(newVal);
      },
    });

    watch([wallet], async () => {
      const wallAddr = getWalletAddress();
        if(wallet.value && wallAddr){
            notify({
  title: "Wallet Connected",
  text: "You have connected the wallet address " + wallAddr.toString(),
});
            pubKey.value = wallAddr.toString().substr(0,6);
        }
    });

    // wallet
    const chosenWallet = computed({
      get() {
        return getWalletName();
      },
      set(newVal: WalletName | null) {
        setWallet(newVal, getClusterURL());
      },
    });

    return {
      Cluster,
      chosenCluster,
      WalletName,
      chosenWallet,
      wallet,
      connoptions,
      pubKey
    };
  },
});
