import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "mb-2" }
const _hoisted_2 = {
  key: 0,
  class: "mb-2"
}
const _hoisted_3 = {
  key: 1,
  class: "mb-2"
}
const _hoisted_4 = {
  key: 2,
  class: "mb-2"
}
const _hoisted_5 = {
  key: 3,
  class: "mb-2"
}
const _hoisted_6 = {
  key: 4,
  class: "mb-2"
}
const _hoisted_7 = {
  key: 5,
  class: "mb-2"
}
const _hoisted_8 = { class: "mb-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, "Base rate: " + _toDisplayString(_ctx.schedule.baseRate) + " USDC/s", 1),
    (_ctx.schedule.tier1)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, " T1 reward rate: " + _toDisplayString(_ctx.schedule.tier1.rewardRate) + " USDC/s ", 1))
      : _createCommentVNode("", true),
    (_ctx.schedule.tier1)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, " T1 required tenure: " + _toDisplayString(_ctx.schedule.tier1.requiredTenure) + " sec ", 1))
      : _createCommentVNode("", true),
    (_ctx.schedule.tier2)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, " T2 reward rate: " + _toDisplayString(_ctx.schedule.tier2.rewardRate) + " USDC/s ", 1))
      : _createCommentVNode("", true),
    (_ctx.schedule.tier2)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, " T2 required tenure: " + _toDisplayString(_ctx.schedule.tier2.requiredTenure) + " sec ", 1))
      : _createCommentVNode("", true),
    (_ctx.schedule.tier3)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, " T3 reward rate: " + _toDisplayString(_ctx.schedule.tier3.rewardRate) + " USDC/s ", 1))
      : _createCommentVNode("", true),
    (_ctx.schedule.tier3)
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, " T3 required tenure: " + _toDisplayString(_ctx.schedule.tier3.requiredTenure) + " sec ", 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_8, " Denominator: " + _toDisplayString(_ctx.schedule.denominator), 1)
  ]))
}