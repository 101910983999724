<template>
  <TheNavBar />
  <div class="container grabb p-10 pt-20 mx-auto h-screen">
    <notifications       position="bottom right" />
    <router-view />
  </div>
</template>

<script>
import TheNavBar from '@/components/TheNavBar';
export default {
  components: { TheNavBar },
};
</script>

<style>
* {
  font-family: 'Poppins', sans-serif;
}
.poppins {
  font-family: 'Poppins', sans-serif;
}
.starforge{
    font-family: starforge,sans-serif;
}
.sharetech {
    font-family: 'Share Tech', monospace;

}
.grabb {
  background-color: rgba(0,0,0,0.5);
}
input[type='radio']:checked + span {
  @apply text-black;
}
</style>
